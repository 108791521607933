import React from 'react';
import cls from 'classnames';
import FocusTrap from 'focus-trap-react';

import { Popover as PopoverTPA } from 'wix-ui-tpa/cssVars';

import { Box } from '../Box';
import { Paper } from '../Paper';
import { usePortalContainer } from '../Portal';

import classes from './Popover.scss';

interface IProps
  extends Omit<
    React.ComponentProps<typeof PopoverTPA>,
    'onClickOutside' | 'shown'
  > {
  empty?: boolean;
  isOpen?: boolean;
  focusTrap?: boolean;
  content: React.ReactNode;
  children: React.ReactNode;

  onClose(): void;
}

export function Popover(props: IProps) {
  const {
    focusTrap,
    children,
    className,
    onClose,
    empty,
    content,
    isOpen,
    ...rest
  } = props;

  const portal = usePortalContainer();

  return (
    <PopoverTPA
      fixed
      appendTo={portal}
      shown={isOpen}
      onEscPress={onClose}
      onClickOutside={onClose}
      disableClickOutsideWhenClosed
      className={cls(classes.root, className)}
      contentClassName={classes.content}
      zIndex={classes.zIndex as unknown as number}
      {...rest}
    >
      <PopoverTPA.Element>{children}</PopoverTPA.Element>
      <PopoverTPA.Content>
        <FocusTrap active={focusTrap ? isOpen : false}>
          {empty ? (
            content
          ) : (
            <Paper
              bw
              topBottomBorders={false}
              sideBorders={false}
              shadow={false}
            >
              <Box padding="SP4" direction="vertical">
                {content}
              </Box>
            </Paper>
          )}
        </FocusTrap>
      </PopoverTPA.Content>
    </PopoverTPA>
  );
}

Popover.displayName = 'wui/Popover';
Popover.defaultProps = {
  empty: false,
  placement: 'top',
  minWidth: 200,
  maxWidth: 320,
};
