import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import {
  selectCanAddMembers,
  selectCanInviteMembers,
  selectIsGroupPending,
} from 'store/selectors';

import {
  AddUser as AddUserIcon,
  ChevronDown as ChevronDownIcon,
  ChevronUp as ChevronUpIcon,
  Email as EmailIcon,
  Plus as PlusIcon,
} from '@wix/wix-ui-icons-common/on-stage';
import { Button } from 'wui/Button';
import { Menu, MenuItem } from 'wui/Menu';

import { AddMembersDialog } from './AddMembersDialog';
import { InviteMembersDialog } from './InviteMembersDialog';
import { PendingApprovalDialog } from './PendingApprovalDialog';

import { INVITE_MEMBERS_BUTTON } from './dataHooks';

interface IProps {
  groupId: string;
}

interface IDialogsState {
  addMembers?: boolean;
  inviteMembers?: boolean;
  pendingApproval?: boolean;
}

export function AddInviteMembers(props: IProps) {
  const { groupId } = props;

  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [dialogs, setDialogs] = useState<IDialogsState>({});

  const canAdd = useSelector(selectCanAddMembers(groupId));
  const canInvite = useSelector(selectCanInviteMembers(groupId));
  const isPending = useSelector(selectIsGroupPending(groupId));

  if (!canAdd && !canInvite) {
    return null;
  }

  return (
    <>
      <Menu
        controlled
        isOpen={isMenuOpen}
        onClose={handleMenuClose}
        popoverProps={{ showArrow: false }}
        element={
          <Button
            outlined
            fullWidth={isMobile}
            onClick={handleMenuOpen}
            data-hook={INVITE_MEMBERS_BUTTON}
            area-label={t('groups-web.a11y.invite-members')}
            prefixIcon={<PlusIcon />}
            suffixIcon={isMenuOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          >
            {t('groups-web.add-invite.button.label')}
          </Button>
        }
      >
        {canInvite && (
          <MenuItem
            prefixIcon={<EmailIcon />}
            content={t('groups-web.add-invite.menu.invite.title')}
            subtitle={t('groups-web.add-invite.menu.invite.subtitle')}
            onClick={handleDialogOpen('inviteMembers')}
          />
        )}
        {canAdd && (
          <MenuItem
            prefixIcon={<AddUserIcon />}
            content={t('groups-web.add-invite.menu.add.title')}
            subtitle={t('groups-web.add-invite.menu.add.subtitle')}
            onClick={handleDialogOpen('addMembers')}
          />
        )}
      </Menu>

      <AddMembersDialog
        groupId={props.groupId}
        isOpen={dialogs.addMembers}
        onClose={handleDialogClose('addMembers')}
      />

      <InviteMembersDialog
        groupId={props.groupId}
        isOpen={dialogs.inviteMembers}
        onClose={handleDialogClose('inviteMembers')}
      />

      <PendingApprovalDialog
        isOpen={dialogs.pendingApproval}
        onClose={handleDialogClose('pendingApproval')}
      />
    </>
  );

  function handleDialogOpen(dialog: keyof IDialogsState) {
    return function () {
      handleMenuClose();
      setDialogs({ [dialog]: true });
    };
  }

  function handleDialogClose(dialog: keyof IDialogsState) {
    return function () {
      handleMenuClose();
      setDialogs({ [dialog]: false });
    };
  }

  function handleMenuOpen() {
    if (isPending) {
      setDialogs({ pendingApproval: true });
    } else {
      setIsMenuOpen(true);
    }
  }

  function handleMenuClose() {
    setIsMenuOpen(false);
  }
}

AddInviteMembers.displayName = 'AddInviteMembers';
