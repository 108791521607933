import * as React from 'react';

export function AnonymousIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      {...props}
    >
      <defs>
        <style>{'.cls-anonymous-icon{fill: inherit}'}</style>
      </defs>
      <ellipse
        className="cls-anonymous-icon"
        cx={103.7}
        cy={74.3}
        rx={38.3}
        ry={38.7}
      />
      <path style={{ fill: 'none' }} d="M0 0h200v200H0z" />
      <path
        className="cls-anonymous-icon"
        d="M188.4 200c-7.4-43.6-44.2-76.8-88.4-76.8S19 156.4 11.6 200Z"
      />
    </svg>
  );
}

AnonymousIcon.displayName = 'wui/Avatar/AnonymousIcon';
