import React from 'react';
import { take } from 'lodash';
import { useSelector } from 'react-redux';
import { useSettings } from '@wix/tpa-settings/react';

import { type IMember } from 'api/members/types';
import { selectCurrentUser, selectIsSiteAdmin } from 'store/selectors';
import * as common from 'settings/common';

import { Wire } from 'wui/Wire';
import { Stack } from 'wui/Stack';
import { MemberBadge } from 'wui/MemberBadge';

import { MEMBER_BADGES_LIST } from './dataHook';

import classes from './MemberBadges.scss';

interface IProps {
  limit?: number;
  member?: IMember;
}

export function MemberBadges(props: IProps) {
  const { limit = 2, member } = props;

  const settings = useSettings();
  const user = useSelector(selectCurrentUser);
  const isSiteAdmin = useSelector(selectIsSiteAdmin);
  const isCurrentUser = member?.memberId === user?.memberId;

  const isAllowed = isCurrentUser || isSiteAdmin || !member?.isPrivate;

  if (!member?.badges?.length || !isAllowed) {
    return null;
  }

  return (
    <Wire
      cssVarName="showMembersBadges"
      legacyFallback={settings.get(common.settings.showMembersBadges)}
    >
      <Stack
        wrap
        gap="SP1"
        direction="horizontal"
        className={classes.root}
        data-hook={MEMBER_BADGES_LIST}
      >
        {take(member.badges, limit).map((badge) => (
          <MemberBadge key={badge.id} badge={badge} className={classes.badge} />
        ))}
      </Stack>
    </Wire>
  );
}

MemberBadges.displayName = 'MemberBadges';
