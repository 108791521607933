import React, { useEffect } from 'react';
import cls from 'classnames';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { SwitchTransition } from 'react-transition-group';

import {
  Camera as CameraIcon,
  Delete as DeleteIcon,
  Replace as ReplaceIcon,
} from '@wix/wix-ui-icons-common/on-stage';

import { Button } from 'wui/Button';
import { Fade } from 'wui/Fade';
import { Media } from 'wui/Media';
import { ButtonGroup } from 'wui/ButtonGroup';

import { getPreview } from './helpers';
import { type EditableCoverImage } from './types';

import classes from './CoverImageEditor.scss';

interface IProps {
  value: EditableCoverImage;

  onChange(value?: EditableCoverImage): void;
}

export function CoverImageEditor(props: IProps) {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const $input = React.useRef<HTMLInputElement>(null);

  const [media, setMedia] = React.useState(getPreview(props.value));

  return (
    <div className={cls(classes.root, { [classes.mobile]: isMobile })}>
      <SwitchTransition>
        {(() => {
          if (!media) {
            return (
              <Fade key="empty">
                <Button
                  outlined
                  variant="basic"
                  onClick={handleUploadClick}
                  prefixIcon={<CameraIcon />}
                  size={isMobile ? 'tiny' : 'medium'}
                >
                  {t('groups-web.group.settings.details.upload_image')}
                </Button>
              </Fade>
            );
          }

          return (
            <Fade key={media}>
              <>
                <Media
                  fluid
                  src={media as string}
                  className={classes.image}
                  resize="cover"
                />

                <ButtonGroup
                  className={classes.actions}
                  orientation={isMobile ? 'vertical' : 'horizontal'}
                >
                  <Button
                    outlined
                    fullWidth
                    variant="basic"
                    onClick={handleUploadClick}
                    prefixIcon={<ReplaceIcon />}
                    size={isMobile ? 'tiny' : 'medium'}
                  >
                    {t('groups-web.group.settings.details.change_image')}
                  </Button>

                  <Button
                    fullWidth
                    variant="basic"
                    onClick={handleDeleteClick}
                    prefixIcon={<DeleteIcon />}
                    size={isMobile ? 'tiny' : 'medium'}
                  >
                    {t('groups-web.group.settings.details.delete_image')}
                  </Button>
                </ButtonGroup>
              </>
            </Fade>
          );
        })()}
      </SwitchTransition>

      <input
        hidden
        accept="image/*"
        type="file"
        ref={$input}
        onChange={handleFileInputChange}
      />
    </div>
  );

  function handleDeleteClick() {
    props.onChange({});
    setMedia(undefined);
  }

  function handleUploadClick() {
    $input.current?.click();
  }

  function handleFileInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const [file] = Array.from(event.target.files!);

    if (media) {
      URL.revokeObjectURL(media);
    }

    setMedia(URL.createObjectURL(file));

    event.target.value = null as any;
    props.onChange(file);
  }
}

CoverImageEditor.displayName = 'CoverImageEditor';
