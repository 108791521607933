import React from 'react';
import cls from 'classnames';

import { type AvatarSize, Image, ImageResizeOptions } from 'wix-ui-tpa/cssVars';

import { Box } from '../Box';
import { Typography } from '../Typography';
import { type TPAComponentProps } from '../types';

import { getInitials } from './helpers';
import { AnonymousIcon } from './AnonymousIcon';

import styles from './Avatar.scss';

export interface IAvatarProps extends TPAComponentProps {
  src?: string;
  name?: string;
  lettersLimit?: number;
  size?: keyof typeof AvatarSize;
}

export function Avatar(props: IAvatarProps) {
  const {
    src,
    name,
    className,
    size = 'medium',
    lettersLimit = 2,
    ...rest
  } = props;

  const isAnonymous = !src && !name;

  if (isAnonymous) {
    return (
      <AnonymousIcon
        className={cls(className, styles.root, styles[size], styles.anonymous)}
        {...rest}
      />
    );
  }

  if (!src && name) {
    return (
      <Box
        title={name}
        align="center"
        direction="vertical"
        verticalAlign="middle"
        className={cls(className, styles.root, styles[size])}
        {...rest}
      >
        <Typography as="span" className={styles.initials}>
          {getInitials(name)}
        </Typography>
      </Box>
    );
  }

  return (
    <Image
      fluid
      src={src}
      alt={name}
      aspectRatio="square"
      resize={ImageResizeOptions.cover}
      className={cls(className, styles.root, styles[size])}
      {...rest}
    />
  );
}

Avatar.displayName = 'wui/Avatar';
